<template>
  <div
    class="app-container apply__myApply"
    style="background-color: #f6f6f6; padding: 180px 0px 150px; min-height: 800px;"
  >
    <!-- 비밀번호 바꾸기 form -->
    <div style="width: 500px; margin: 0 auto;">
      <div class="apply__myApply--container">
        <div class="align-top">
          <h1 class="apply__tit font-bold text-2xl mt-5 mb-12 mt-10 pb-3">
            <span class="highlight">
              <span>🔑 비밀번호 변경</span>
            </span>
          </h1>
          <p class="font-primary-color ml-1 text-sm mb-12">
            <i
              class="highlight__star"
              style="font-size: 16px; top: 4px;"
            >*</i>
            비밀번호는 영문, 숫자를 포함하여 공백없이 8 ~ 20자로 설정해주세요.
          </p>
          <el-form
            class="custom-form"
            inline
            style="width: 250px; margin: 0 auto;"
          >
            <div>
              <el-form-item prop="password">
                <el-input
                  v-model.trim="password"
                  show-password
                  :minlength="8"
                  :maxlength="20"
                  :placeholder="$t('recruit.form.password')"
                  type="password"
                  class="filter-item"
                  style="width: 235px"
                />
              </el-form-item>
              <el-form-item prop="passwordConfirm">
                <el-input
                  v-model.trim="passwordConfirm"
                  show-password
                  :minlength="8"
                  :maxlength="20"
                  :placeholder="$t('recruit.form.passwordConfirm')"
                  type="password"
                  class="filter-item"
                  style="width: 235px"
                  @keyup.native.enter="changePassword"
                />
              </el-form-item>
            </div>
          </el-form>
          <div class="applyNew__btnWrap">
            <button
              style="width: 220px; height: 60px;"
              class="mt-4 customBtn lg white"
              @click="changePassword"
            >
              <span style="font-size: 18px;">비밀번호 변경</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- // 비밀번호 바꾸기 form -->
  </div>
</template>

<script>
import { changePassword } from '@/api/applicant';
import '@/plugins/element.js';

export default {
  name: 'PasswordPage',
  data() {
    return {
      password: '',
      passwordConfirm: '',
    };
  },
  methods: {
    validatePassword() {
      let isPwVaild = false;
      const pw = this.password;
      const pwConfirm = this.passwordConfirm;
      const num = pw.search(/[0-9]/g);
      const eng = pw.search(/[a-z]/ig);

      if(pw.length < 8 || pw.length > 20){
        this.$message({
          message: '비밀번호는 8자리 ~ 20자리 이내로 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(pw.search(/\s/) != -1){
        this.$message({
          message: '비밀번호는 공백 없이 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(num < 0 || eng < 0 ){
        this.$message({
          message: '비밀번호를 영문,숫자를 혼합하여 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(pw !== pwConfirm){
        this.$message({
          message: '비밀번호가 다릅니다.',
          type: 'error'
        });
      }else {
        isPwVaild = true;
      }
      return isPwVaild;
    },
    async changePassword() {
      if(!this.validatePassword()){
        return false;
      }
      const userInfo = this.$store.getters.userForm;
      const result = await changePassword({
        name: userInfo.name,
        birthday: userInfo.birthday,
        email: userInfo.email,
        password: this.password
      });
      if(!result.data.success){
        this.$message({
          message: result.data.message,
          type: 'error'
        });
        return false;
      }
      this.$router.push('/recruit/my-apply');
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/apply.scss';
</style>
